import React from "react";
import css from "./style.module.css";
import { Get, Post } from "../../../Services/Server";
import Url from "../../../Services/Url";
import { useParams } from "react-router-dom";
import Head from "../../../Components/Head/Head";
import { useState, useEffect } from "react";
import DataTable from "../../../Components/DataTable/DataTable";
import Time from "../../../Services/Time";
import Button from "../../../Components/Button/Button";
import TaskItem from "../../../Components/TaskItem/TaskItem";
import ExpenseRemark from "../../../Modals/ExpenseRemark/ExpenseRemark";
import { ModalAction } from "../../../Routes/NavRouter";
import User from "../../../Services/User";
import { useNavigate } from "react-router-dom";
import ExpensesItem from "../../../Components/ExpensesItem/ExpensesItem";
import Calc from "../../../Services/Calc";
import ModalOption from "../../../Services/ModalOption";
import Svg from "../../../Components/Svg/Svg";
import SvgOptions from "../../../Components/Svg/SvgOptions";
import { toast } from "react-toastify";
import Utils from "../../../Services/Utills";
const Reader = (rec) => {
	return [
		rec.name,
		rec.quantity,
		"₦" + rec.price,
		"₦" + rec.quantity * rec.price,
		rec.receiptId ? (
			<div className={css.receiptThumb}>
				<img src={Url.img.thumb(rec.receiptId)} alt="receipt" width={200} />
			</div>
		) : (
			<Svg>{SvgOptions.Receipt}</Svg>
		),
	];
};

const ExpensesView = () => {
	const { id } = useParams();
	const Navigate = useNavigate();
	const [tastExpense, setExpense] = useState();
	const heading = ["Item Name", "Unit", "Price", "Amount", "Upload"];
	const biodata = User.getBiodata();
	const [current, setCurrent] = useState({ heading });
	const flex = [20, 20, 20, 20, 20];
	const submittedBy = () => {
		const user = tastExpense?.task?.expenses?.submittedBy;
		return user?.firstname + " " + user?.lastname;
	};
	const submittedAt = () => {
		const at = tastExpense?.task?.expenses?.submittedAt;
		return Time.getDateTime(at);
	};
	const ApprovedBy = () => {
		const by = tastExpense?.task?.expenses?.expenseApprovals?.approvedBy;
		return by.firstname + " " + by.lastname;
	};
	const PaidBy = () => {
		const by = tastExpense?.task?.expenses?.expenseApprovals?.payment?.paidBy;
		return by.firstname + " " + by.lastname;
	};
	const ScheduledBy = () => {
		console.log(tastExpense?.task?.expenses?.expenseApprovals?.schedule);

		const by =
			tastExpense?.task?.expenses?.expenseApprovals?.schedule?.scheduledBy;
		return by?.firstname + " " + by?.lastname;
	};

	const init = () => {
		Get(Url.expenses.get(id))
			.then((resp) => {
				setExpense(resp);
				console.log(resp);

				setCurrent({ ...current, data: resp.task.expenses.expenseDetailsList });
			})
			.catch((err) => {
				console.log(err);
			});
	};
	useEffect(() => {
		init();
	}, []);

	const remark = (status) => {
		const { expenses } = tastExpense.task;
		const options = {
			element: ExpenseRemark,
			title: "Payment Remark",
			expenses,
			status,
			amount: tastExpense.task.expenses.total - getPaid(),
			paidBy: { id: biodata.id },
			closeModal: () => {
				ModalAction.closeModal();
				Navigate("../");
			},
		};
		ModalAction.openModal(options);
	};
	const schedule = () => {
		const { expenses } = tastExpense.task;
		const schedule = {
			ScheduledBy,
			amount: tastExpense.task.expenses.total - getPaid(),
			currency: 1,
		};
		expenses.expenseApprovals.schedule = schedule;

		Post(Url.expenses.saveSchedule, expenses)
			.then((resp) => {
				toast("Payment Scheduled");
				Navigate("../");
			})
			.catch((err) => {
				console.log(err);
			});
	};
	let Cal;
	if (tastExpense) {
		Cal = new Calc(tastExpense);
	}
	const getPaid = () => {
		return Cal.getPaid();
	};
	const getBalance = () => {
		return Cal.getBalance(css);
	};

	const onExpenseClick = (e) => {
		const option = ModalOption();
		option.element = Expanded;
		option.title = e.name;
		option.expense = e;
		option.closeModal = ModalAction.closeModal();
		ModalAction.openModal(option);
	};

	return (
		<div className={css.TDPage}>
			{tastExpense && (
				<>
					<div className={css.TDLeft}>
						<Head title={"Expense for " + tastExpense?.task?.title}></Head>
						{/* <div>
							<div className={css.amountTitle}>Total Amount:</div>
							{getBalance()}
						</div> */}
						<div>
							{current.data && (
								<DataTable
									reader={Reader}
									onClick={onExpenseClick}
									flex={flex}
									transform={Utils.toPascalCase}
								>
									{current}
								</DataTable>
							)}
						</div>
						<div className={css.expenseFoot}>
							<div>
								<div>Total Expenses:</div>
								<div>₦{tastExpense.task?.expenses?.total}</div>
							</div>
							{/* <div>
								<div>Total Paid:</div>
								<div>₦{getPaid()}</div>
							</div> */}

							<div>
								<div>Submitted By:</div>
								<div>{submittedBy()} </div>
							</div>
							<div>
								<div>Submited At:</div>
								<div>{submittedAt()}</div>
							</div>
						</div>
						<div className={css.Approvals}>
							{tastExpense?.task?.expenses?.expenseApprovals && (
								<div className={css.Approve}>
									{tastExpense?.task?.expenses?.expenseApprovals?.status ? (
										<div>
											<div className={css.Title}>Expense Approved</div>
											<div className={css.Details}>
												<div>Approved By : {ApprovedBy()}</div>
												<div>
													Approved At:{" "}
													{Time.getDateTime(
														tastExpense?.task?.expenses?.expenseApprovals
															?.createdAt
													)}
												</div>
											</div>
											<div className={css.Remark}>
												Remark :{" "}
												{tastExpense?.task?.expenses?.expenseApprovals?.remark}
											</div>
										</div>
									) : (
										<div className={css.Title}>Expense Disapproved</div>
									)}
								</div>
							)}
							{tastExpense?.task?.expenses?.expenseApprovals?.payment ? (
								<div>
									{tastExpense?.task?.expenses?.expenseApprovals?.payment
										?.status ? (
										<div className={css.Approve}>
											<div className={css.Title}>Payment Approved</div>
											<div className={css.Details}>
												<div>Approved By : {PaidBy()}</div>
												<div>
													Approved At:{" "}
													{Time.getDateTime(
														tastExpense.task.expenses.expenseApprovals.payment
															.paidAt
													)}
												</div>
											</div>
											<div className={css.Remark}>
												Remark :{" "}
												{
													tastExpense?.task?.expenses?.expenseApprovals?.payment
														?.remark
												}
											</div>
										</div>
									) : (
										<div className={css.Approve}>
											<div className={css.Title}>Payment Disapproved</div>
											<div className={css.Details}>
												<div>Disapproved By : {PaidBy()}</div>
												<div>
													Disapproved At:{" "}
													{Time.getDateTime(
														tastExpense?.task?.expenses?.expenseApprovals
															?.payment.paidAt
													)}
												</div>
											</div>
											<div className={css.Remark}>
												Remark :{" "}
												{
													tastExpense?.task?.expenses?.expenseApprovals?.payment
														.remark
												}
											</div>
										</div>
									)}
								</div>
							) : (
								<div className={css.controls}>
									<div>
										<Button
											onClick={() => remark(true)}
											className="Active Btn-Mid"
										>
											Approve Payment
										</Button>
									</div>
									<div>
										<Button
											onClick={() => remark(false)}
											className="Inverse Btn-Mid"
										>
											Disapprove Payment
										</Button>
									</div>
								</div>
							)}
							{tastExpense?.task?.expenses?.expenseApprovals?.payment?.status &&
								(tastExpense.task.expenses.expenseApprovals.schedule ? (
									<div className={css.Approve}>
										<div className={css.Title}>Payment Scheduled</div>
										<div className={css.Details}>
											{/* <div>Scheduled By: {ScheduledBy()}</div> */}
											<div>
												Scheduled At:{" "}
												{Time.getDateTime(
													tastExpense?.task?.expenses?.expenseApprovals
														?.schedule?.scheduledAt
												)}
											</div>
										</div>
									</div>
								) : (
									<div className={css.controls}>
										<Button
											onClick={() => schedule()}
											className="Active Btn-Mid"
										>
											Schedule Payment
										</Button>
									</div>
								))}
						</div>
					</div>
					<div className={css.TDRight}>
						<div className={css.Title}>Task Details</div>
						<TaskItem>{tastExpense.task}</TaskItem>
						<div className={css.Title}>Related Expenses</div>
						<div className={css.ExpensesList}>
							{tastExpense?.expensesList?.map((exp) => (
								<ExpensesItem key={exp.id}>{exp}</ExpensesItem>
							))}
						</div>
					</div>
				</>
			)}
		</div>
	);
};

const Expanded = ({ children }) => {
	const { expense } = children;
	console.log(Url.img.get(expense?.receiptId));
	useEffect(() => {
		Get(Url.img.get(expense?.receiptId))
			.then((resp) => {
				console.log(resp);
			})
			.catch((err) => {
				console.log(err);
			});
	});
	return (
		<div className={css.ExpandedView}>
			<div className={css.ReceiptObject}>
				{expense?.receiptType?.includes("pdf") ? (
					<object
						data={Url.img.get(expense?.receiptId)}
						width={800}
						height={500}
						type={expense?.receiptType}
					/>
				) : (
					<img src={Url.img.get(expense?.receiptId)} />
				)}
			</div>
		</div>
	);
};

export default ExpensesView;
