import css from "./Companies.module.css";
import PageHead from "../../../Components/PageHead/PageHead";
import { useState, useEffect } from "react";
import Url from "../../../Services/Url";
import { Get } from "../../../Services/Server";
import DataTable from "../../../Components/DataTable/DataTable";
import { useNavigate } from "react-router-dom";
import Utils from "../../../Services/Utills";

const CompanyList = ({ navoption }) => {
	const [list, setList] = useState([]);
	const [page, setPage] = useState({ pageable: null, totalPage: null });
	const [data, setData] = useState([]);
	const [isSearching, setIsSearching] = useState(false);
	const navigate = useNavigate();
	const reader = (e) => {
		return [
			e.name,
			e.email,
			e.taskCount + " Task" + (e.taskCount > 1 ? "s" : ""),
			"View",
		];
	};
	const loadPage = (pg) => {
		Get(Url.company.get.page(pg)).then((resp) => {
			console.log(resp);
			setList(resp.content);
			setPage({ pageable: resp.pageable, totalPage: resp.totalPages });
			setData(resp.content);
		});
	};
	const handlePagination = (pg) => {
		if (isSearching) {
			const keyword = document.querySelector(".search-input").value; // Adjust selector as per your search input
			Get(Url.company.search(keyword, pg)).then((resp) => {
				setData(resp.content);
				setPage({ pageable: resp.pageable, totalPage: resp.totalPages });
			});
		} else {
			loadPage(pg);
		}
	};
	useEffect(() => {
		loadPage(0);
	}, []);
	const handleAddComp = () => {
		navigate("Create");
	};
	const handleClick = (e) => {
		navigate(`${e.id}`);
	};
	const onSearch = (keyword) => {
		if (keyword.trim()) {
			Get(Url.company.search(keyword, 0)).then((resp) => {
				console.log("search", keyword);

				setData(resp.content);
				setPage({ pageable: resp.pageable, totalPage: resp.totalPages });
				setIsSearching(true);
			});
		} else {
			loadPage(0);
		}
	};
	const heading = ["Company", "Email", "Tasks", "Actions"];
	return (
		<div className={css.Companies}>
			<PageHead
				onSearch={onSearch}
				navoption={navoption}
				addBtn={{ text: "Company", onClick: handleAddComp }}
			></PageHead>
			<div className={css.Record}>
				<DataTable
					handlePagination={handlePagination}
					pageable={page.pageable}
					totalPages={page.totalPage}
					onClick={handleClick}
					flex={[30, 30, 30, 10]}
					reader={reader}
					transform={Utils.toPascalCase}
				>
					{{ data, heading }}
				</DataTable>
			</div>
		</div>
	);
};

export default CompanyList;
