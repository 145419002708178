import "./PieChart.css";
import Charts from "./Charts";
import { useEffect, useState } from "react";
import * as echarts from "echarts";
import Url from "../../Services/Url";
import { Get } from "../../Services/Server";

const Piechart = ({ chartId, dashboardType = "maritime" }) => {
	const currentDate = new Date();
	const [filterYear, setFilterYear] = useState(currentDate.getFullYear());
	const [filterMonth, setFilterMonth] = useState(currentDate.getMonth() + 1);
	// ECharts instance
	const [myChart, setChart] = useState(null);
	// Fetched counts from the endpoint
	const [counts, setCounts] = useState(null);
	// Chart data derived from counts
	const [data, setData] = useState([]);

	// Build chart data using the fetched counts
	const getChartData = () => {
		if (!counts) return [];
		return [
			{
				value: counts.installationCount,
				name: "Installation",
				itemStyle: { color: "#F64740" },
			},
			{
				value: counts.repairCount,
				name: "Repairs",
				itemStyle: { color: "#14B8FF" },
			},
			{
				value: counts.maintenanceCount,
				name: "Maintenance",
				itemStyle: { color: "#FAC815" },
			},
			{
				value: counts.surveyCount,
				name: "Survey",
				itemStyle: { color: "#8A2BE2" },
			},
			{
				value: counts.decommissioningCount,
				name: "Decommissioning",
				itemStyle: { color: "#32CD32" },
			},
		];
	};

	// Initialize the chart instance once on mount
	useEffect(() => {
		const chartDom = document.getElementById(chartId);
		if (chartDom) {
			const chartInstance = echarts.init(chartDom);
			setChart(chartInstance);
		}
	}, [chartId]);

	useEffect(() => {
		const fetchCounts = async () => {
			try {
				const url =
					dashboardType === "enterprise"
						? Url.enterprise.taskTypeCounts(filterMonth, filterYear)
						: Url.ui.taskTypeCounts(filterMonth, filterYear);
				const response = await Get(url);
				const fetchedData =
					response.data !== undefined ? response.data : response;
				setCounts(fetchedData);
			} catch (error) {}
		};
		fetchCounts();
	}, [filterYear, filterMonth, dashboardType]);

	useEffect(() => {
		if (counts && myChart) {
			const dt = getChartData();
			setData(dt);
			const option = Charts.getPie
				? Charts.getPie(dt)
				: {
						title: { text: "Task Types", left: "center" },
						tooltip: { trigger: "item" },
						series: [
							{
								name: "Task Type",
								type: "pie",
								radius: "50%",
								data: dt,
								emphasis: {
									itemStyle: {
										shadowBlur: 10,
										shadowOffsetX: 0,
										shadowColor: "rgba(0, 0, 0, 0.5)",
									},
								},
							},
						],
				  };
			myChart.setOption(option);
		}
	}, [counts, myChart]);

	// Calculate total for percentage display
	const sum = data.reduce((total, item) => total + Number(item.value || 0), 0);

	const handleYearChange = (e) => {
		setFilterYear(Number(e.target.value));
	};

	const handleMonthChange = (e) => {
		setFilterMonth(Number(e.target.value));
	};

	const currentYearVal = currentDate.getFullYear();
	const yearOptions = [];
	for (let y = 2023; y <= currentYearVal; y++) {
		yearOptions.push(y);
	}
	const monthNames = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	return (
		<div className="PieChart">
			<div className="PieHead">
				<div>
					<h4>Task Type</h4>
				</div>
				{/* Filter dropdowns */}
				<div className="FilterControls">
					<select value={filterYear} onChange={handleYearChange}>
						{yearOptions.map((y) => (
							<option key={y} value={y}>
								{y}
							</option>
						))}
					</select>
					<select value={filterMonth} onChange={handleMonthChange}>
						{Array.from({ length: 12 }, (_, i) => i + 1).map((m) => (
							<option key={m} value={m}>
								{monthNames[m - 1]}
							</option>
						))}
					</select>
				</div>
			</div>
			<div id={chartId} style={{ width: "100%", height: "300px" }}></div>
			<div className="ChartDetails">
				{data.map((dt, index) => (
					<div className="ChartItem" key={index}>
						<div className="ChartItemName">
							<div
								className="ChartDot"
								style={{ backgroundColor: dt.itemStyle.color }}
							></div>
							<div>{`${dt.value} ${dt.name}`}</div>
						</div>
						<div className="ChartPercent">
							{sum
								? Math.round((Number(dt.value) * 1000) / sum) / 10
								: Math.round(100 / data.length)}
							%
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

Piechart.defaultProps = {
	chartId: "PieChart",
};

export default Piechart;
