import css from "./style.module.css";
import PageHead from "../../../Components/PageHead/PageHead";
import { Get } from "../../../Services/Server";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Url from "../../../Services/Url";
import DataTable from "../../../Components/DataTable/DataTable";
import Utils from "../../../Services/Utills";

const Reader = (rec) => {
	const exp = rec;
	console.log(rec);

	return [
		"#" + exp.total,
		rec.jobNumber,
		exp.submittedBy,
		exp.location,
		exp.submittedAt,
	];
};

const ExpensesList = ({ navoption }) => {
	const heading = ["Job Number", "Submitted By", "Location", "Submitted on"];
	const navigate = useNavigate();

	const [current, setCurrent] = useState({
		heading: ["Current Expenses", ...heading],
	});
	const [previous, setPrevious] = useState({
		heading: ["Previous Expenses", ...heading],
	});

	const [view, setView] = useState("current"); // Default view

	const flex = [20, 20, 20, 20, 20];

	const loadCurrent = (page) => {
		Get(Url.expenses.enterpriseTasks(false, page))
			.then((resp) => {
				console.log(resp);

				setCurrent({ ...current, data: resp });
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const loadPrevious = (page) => {
		Get(Url.expenses.enterpriseTasks(true, page))
			.then((resp) => {
				console.log(resp);

				setPrevious({ ...previous, data: resp });
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		loadCurrent(0);
		loadPrevious(0);
	}, []);

	const handlePagination = (page) => {
		if (view === "current") loadCurrent(page);
		else if (view === "previous") loadPrevious(page);
	};

	const onExpenseClick = (e) => {
		console.log(e);
		
		navigate("" + e.id );
	};

	const dropOptions = {
		onClick: (e) => {
			const selectedOption = e.find((o) => o.selected);
			if (selectedOption) {
				const selectedView = selectedOption.text.toLowerCase();
				if (selectedView === "current expenses") {
					setView("current");
				} else if (selectedView === "previous expenses") {
					setView("previous");
				}
			} else {
				setView("current"); // Default to current expenses
			}
		},
		list: ["Current Expenses", "Previous Expenses"],
	};

	return (
		<div className={css.Page}>
			<PageHead
				navoption={navoption}
				dropOptions={dropOptions}
				filter={view === "current" ? "Current Expenses" : "Previous Expenses"}
			/>
			<div>
				{view === "current" && current.data && (
					<DataTable
						handlePagination={handlePagination}
						reader={Reader}
						onClick={onExpenseClick}
						flex={flex}
					>
						{current}
					</DataTable>
				)}
				{view === "previous" && previous.data && (
					<DataTable
						handlePagination={handlePagination}
						reader={Reader}
						onClick={onExpenseClick}
						flex={flex}
					>
						{previous}
					</DataTable>
				)}
			</div>
		</div>
	);
};

export default ExpensesList;
