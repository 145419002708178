import "./PieChart.css";
import Charts from "./Charts";
import { useEffect, useState } from "react";
import * as echarts from "echarts";
import Url from "../../Services/Url";
import { Get } from "../../Services/Server";

const BarChart = ({ children, chartId, op, dashboardType = "maritime" }) => {
	const roleName =
		dashboardType === "enterprise"
			? op
				? "enterprise-ops"
				: "enterprise"
			: op
			? "operation"
			: "engineer";

	const currentDate = new Date();
	const [filterYear, setFilterYear] = useState(currentDate.getFullYear());
	const [filterMonth, setFilterMonth] = useState(currentDate.getMonth() + 1);

	const [filteredData, setFilteredData] = useState(null);

	// Initialize the chart instance
	const [chartInstance, setChartInstance] = useState(null);
	useEffect(() => {
		const chartDom = document.getElementById(chartId);
		if (chartDom && !chartInstance) {
			setChartInstance(echarts.init(chartDom));
		}
	}, [chartId, chartInstance]);

	const buildUrl = (roleName, month, year) => {
		if (dashboardType === "enterprise") {
			return `${Url.enterprise.taskCountbyDate(roleName, month, year)}`;
		} else {
			return `${Url.ui.taskCountbyDate(roleName, month, year)}`;
		}
	};

	// Fetch filtered data when filter values change
	useEffect(() => {
		const fetchFilteredData = async () => {
			try {
				const url = buildUrl(roleName, filterMonth, filterYear);
				const response = await Get(url);
				const fetchedData = response.data ? response.data : response;
				setFilteredData(fetchedData.content);
			} catch (error) {}
		};
		fetchFilteredData();
	}, [filterYear, filterMonth, roleName]);

	const dataSource = filteredData ? filteredData : children;
	const xdata = (dataSource || []).map((v) => v.firstname);
	const getData = () => {
		if (!dataSource || !Array.isArray(dataSource)) return [];
		return dataSource.map((v) => ({
			value: op ? v.currentOpJobs : v.currentJobs,
			name: v.firstname,
			itemStyle: { color: "#14B8FF" },
		}));
	};

	useEffect(() => {
		if (chartInstance) {
			const seriesData = getData();
			const chartOptions = Charts.getBar(seriesData, xdata);
			chartInstance.setOption(chartOptions);
		}
	}, [chartInstance, dataSource, xdata]);

	const handleYearChange = (e) => {
		setFilterYear(Number(e.target.value));
	};

	const handleMonthChange = (e) => {
		setFilterMonth(Number(e.target.value));
	};

	const currentYear = currentDate.getFullYear();
	const yearOptions = [];
	for (let y = 2023; y <= currentYear; y++) {
		yearOptions.push(y);
	}
	const monthNames = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	return (
		<div style={{ width: "100%" }} className="PieChart">
			<div className="PieHead">
				<div>
					<h4>Jobs Overview</h4>
				</div>
				{/* Filter dropdowns (month and year) */}
				<div className="FilterControls">
					<select value={filterMonth} onChange={handleMonthChange}>
						{Array.from({ length: 12 }, (_, i) => i + 1).map((m) => (
							<option key={m} value={m}>
								{monthNames[m - 1]}
							</option>
						))}
					</select>
					<select value={filterYear} onChange={handleYearChange}>
						{yearOptions.map((y) => (
							<option key={y} value={y}>
								{y}
							</option>
						))}
					</select>
				</div>
			</div>
			<div id={chartId} style={{ width: "100%", height: "500px" }}></div>
		</div>
	);
};

BarChart.defaultProps = {
	chartId: "BarChart",
};

export default BarChart;
