import css from "./Style.module.css";
import AddButton from "../../Components/Form/AddButton/AddButton";
import ExpensesOptions from "./ExpenseOptions";
import Input from "../../Components/Form/Input/Input";
import Utils from "../../Services/Utills";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Button from "../../Components/Button/Button";
import { Post, Get, UploadFile, Error } from "../../Services/Server";
import Url from "../../Services/Url";
import User from "../../Services/User";
import Svg from "../../Components/Svg/Svg";
import SvgOptions from "../../Components/Svg/SvgOptions";
import Time from "../../Services/Time";
import { ModalAction } from "../../Routes/NavRouter";
const Rec = ({ children, flex, onClick, reader }) => {
	const data = reader ? reader(children) : children;
	return (
		<div className="TableRecord">
			{data.map((rec, index) => (
				<div
					onClick={() => (onClick ? onClick(children) : null)}
					style={{ flex: flex[index] + "%" }}
					key={index}
				>
					{rec}
				</div>
			))}
		</div>
	);
};
const ExpenseModal = ({ children }) => {
	const { task, closeModal } = children;
	const [changed, setChanged] = useState(false);
	const [showExpense, setShowExpense] = useState({});
	const [showFormExpense, setShowFormExpense] = useState({});

	const handleCloseModal = () => {
		// load();
		ModalAction.closeModal();
	};
	const toggleExpense = (index) => {
		setShowExpense((prev) => {
			const updatedState = {};
			// Close all items by setting their value to false
			Object.keys(prev).forEach((key) => {
				updatedState[key] = false;
			});
			// Open the clicked item
			updatedState[index] = true;
			return updatedState;
		});
	};

	const [taskExpenses, setTaskExpenses] = useState([]);
	const [newExpense, setNewExpense] = useState(false);
	const [showButton, setShowButton] = useState(true);
	const [formEditable, setFormEditable] = useState(true);
	const [saved, setSaved] = useState(false);

	const expenses = task.expenses
		? task.expenses.expenseApprovals
			? {
					taskId: task.id,
					submitted: false,
					expenseDetailsList: task.expenses.expenseDetailsList.reduce(
						(a, v) => {
							delete v.id;
							return [...a, v];
						},
						[]
					),
			  }
			: task.expenses
		: { taskId: task.id, submitted: false, expenseDetailsList: [] };

	const biodata = User.getBiodata();
	const serial = (obj) => {
		return JSON.parse(JSON.stringify(obj));
	};
	const op = serial(ExpensesOptions);
	const initData = () => {
		if (expenses.expenseDetailsList) {
			return expenses.expenseDetailsList.map((v) => {
				const op = { ...serial(ExpensesOptions) };
				op.id = v.id;
				op.receiptId.value = v.receiptId;
				op.receiptType.value = v.receiptType;
				op.name.value = v.name;
				op.quantity.value = v.quantity;
				op.price.value = v.price;
				op.receipt.value = v.receiptId && Url.img.thumb(v.receiptId);
				return { ...op };
			});
		}

		return [ExpensesOptions];
	};
	const [options, setOptions] = useState(initData());

	const addItem = () => {
		setChanged(true);
		setOptions([...options, serial(op)]);
	};

	const isValidFile = (filename) => {
		const exts = ["png", "gif", "jpg", "jpeg", "jfif", "pjpeg", "pjp", "pdf"];
		if (!filename) return false;
		const ext = filename.split(".").pop()?.toLowerCase();
		return exts.find((ex) => ex.localeCompare(ext) === 0);
	};

	const onUpload = (e, index) => {
		const filename = e.target.value;
		if (isValidFile(filename)) {
			const fileNameArr = filename.split("\\");
			const name = fileNameArr[fileNameArr.length - 1];
			UploadFile(e.target.files[0], name)
				.then((resp) => {
					options[index].receiptId.value = resp.id;
					options[index].receiptType.value = resp.fileType;
					setChanged(true);
				})
				.catch((err) => {
					Error(err);
				});

			Utils.getBase64File(e.target.files[0], (result) => {
				const fileType = result.split(";")[0].substring(5);
				options[index].receipt = { value: result, type: fileType };
				setOptions([...options]);
			});
		} else toast("Selected file is not an image");
	};

	const save = () => {
		return new Promise((resolve) => {
			const dt = options.reduce(
				(a, v) => [
					...a,
					{
						id: v.id,
						name: v.name.value,
						price: v.price.value,
						quantity: v.quantity.value,
						receiptId: v.receiptId.value,
						receiptType: v.receiptType.value,
						currency: 1,
					},
				],
				[]
			);
			expenses.expenseDetailsList = dt;

			Post(Url.expenses.save, expenses)
				.then((resp) => {
					toast("Saved");
					// resolve(resp);
					setChanged(false);
					setSaved(true);
				})
				.catch((err) => {
					Error(err);
				})
				.finally(() => {
					// closeModal();
				});
		});
	};
	const app = {
		id: 1,
	};
	const initial = {
		id: 2,
	};
	const setSubmit = () => {
		Get(Url.expenses.submit(expenses.id, biodata.id))
			.then(() => {
				toast("Submitted");
			})
			.catch((err) => {
				// Error(err);
			})
			.finally(() => {
				closeModal();
			});
		setNewExpense(false);
	};

	const submit = () => {
		if (changed) {
			save().then(() => {
				setSubmit();
			});
		} else {
			setSubmit();
		}
	};

	const addExpense = () => {
		setNewExpense(true);
		setShowButton(false);
	};

	const handleDelete = (index) => {
		options.splice(index, 1);
		setOptions([...options]);
	};

	const disableForm = () => {
		setFormEditable(false);
	};
	const Reader = (rec) => {
		const totalAmount = rec.quantity * rec.price;
		return [
			rec.name,
			"₦" + totalAmount,
			rec.receiptId ? (
				<div className={css.receiptThumb}>
					<img src={Url.img.thumb(rec.receiptId)} alt="receipt" width={200} />
				</div>
			) : (
				<Svg>{SvgOptions.Receipt}</Svg>
			),
		];
	};

	const flex = [20, 20, 20, 20, 20];

	useEffect(() => {
		Get(Url.expenses.getTaskExpenses(task.id)).then((resp) => {
			setTaskExpenses(resp.expensesList);
		});
	});
	const stages = [
		expenses.submitted,
		expenses.expenseApprovals?.status,
		expenses.paymentApprovals?.status,
		expenses.expenseSchedule,
	];

	const progress = (stages.filter((stage) => stage).length / 4) * 100;
	return (
		<div className={css.Modal}>
			{taskExpenses.map((expense, index) => {
				const totalExpenseAmount = expense.expenseDetailsList.reduce(
					(sum, item) => sum + item.quantity * item.price,
					0
				);

				// Determine the stages
				const stages = [
					{ stage: "Expense Submitted", condition: expense.submitted }, // Stage 1: Submitted
					{
						stage: "Expense Approved",
						condition: expense.expenseApprovals?.status,
					}, // Stage 2: Approved
					{
						stage: "Payment Approved",
						condition: expense.expenseApprovals?.payment?.status,
					}, // Stage 3: Payment Approved
					{
						stage: "Payment Scheduled",
						condition: expense.expenseApprovals?.schedule,
					}, // Stage 4: Scheduled
				];

				// Filter out the completed stages
				const completedStages = stages.filter((stage) => stage.condition);

				// Calculate progress
				const progress = (completedStages.length / stages.length) * 100;

				// Get the latest status
				const latestStatus =
					completedStages.length > 0
						? completedStages[completedStages.length - 1].stage
						: "Not Submitted";

				return (
					<div key={index} className={css.FormsContainer}>
						<div className={css.BoxTitle} onClick={() => toggleExpense(index)}>
							<Svg className={css.svg}>
								{!showExpense[index]
									? SvgOptions.ArrowSideFill
									: SvgOptions.ArrowDownFill}
							</Svg>
							<span>{`₦${totalExpenseAmount}`}</span>
						</div>

						{showExpense[index] && (
							<div>
								{/* Check if expense approval is false */}
								{expense.expenseApprovals &&
								!expense.expenseApprovals?.status ? (
									<div className={css.Approve}>
										<div className={css.Title}>Expense Disapproved</div>
										<div className={css.Details}>
											<div>
												Disapproved At:{" "}
												{Time.getDateTime(expense?.expenseApprovals?.createdAt)}
											</div>
										</div>
										<div className={css.Remark}>
											Remark : {expense?.expenseApprovals?.remark}
										</div>
									</div>
								) : expense.expenseApprovals?.payment &&
								  !expense.expenseApprovals?.payment?.status ? (
									// If payment approval is false
									<div className={css.Approve}>
										<div className={css.Title}>Payment Disapproved</div>
										<div className={css.Details}>
											<div>
												Disapproved At:{" "}
												{Time.getDateTime(
													expense?.expenseApprovals?.payment?.paidAt
												)}
											</div>
										</div>
										<div className={css.Remark}>
											Remark : {expense?.expenseApprovals?.payment?.remark}
										</div>
									</div>
								) : (
									// If neither is false, display the progress bar
									expense.submitted && (
										<div className={css.ProgressWrapper}>
											<div className={css.ProgressInfo}>
												<div className={css.StatusText}>{latestStatus}</div>
												<div className={css.StatusText}>{`${Math.round(
													progress
												)}%`}</div>
											</div>
											<div className={css.ProgressBar}>
												<div
													className={css.ProgressFill}
													style={{ width: `${progress}%` }}
												/>
											</div>
										</div>
									)
								)}

								<div className={css.StatusText}>Summary:</div>
								<div className={css.TableRecords}>
									{expense.expenseDetailsList.map((item, idx) => (
										<Rec key={idx} reader={Reader} flex={flex}>
											{item}
										</Rec>
									))}
								</div>
							</div>
						)}
					</div>
				);
			})}

			{task.expenses || newExpense ? (
				<div className={css.FormsContainer}>
					<div
						className={css.BoxTitle}
						onClick={() => setShowFormExpense(!showFormExpense)}
					>
						<Svg className={css.svg}>
							{!showFormExpense
								? SvgOptions.ArrowSideFill
								: SvgOptions.ArrowDownFill}
						</Svg>
					</div>
					{showFormExpense && (
						<div className={css.InputsContainer}>
							<div className={css.Items}>
								{options.map((op, index) => (
									<div key={index} className={css.Item}>
										<div className={css.Img}>
											<input
												id={"file-" + index}
												onChange={(e) => onUpload(e, index)}
												className={css.FileInput}
												type="file"
												disabled={!formEditable} // Make input readonly
											/>
											<label htmlFor={"file-" + index}>
												<RecieptView>{op}</RecieptView>
											</label>
										</div>
										<div className={css.FormCont}>
											<div>
												<Input
													onChange={() => setChanged(true)}
													disabled={!formEditable}
												>
													{op.name}
												</Input>
											</div>
											<div className={css.Details}>
												<div className={css.Value}>
													<Input
														onChange={() => setChanged(true)}
														disabled={!formEditable}
													>
														{op.price}
													</Input>
													<div className={css.Currency}>NGN</div>
												</div>
												<div className={css.Value}>
													<Input
														onChange={() => setChanged(true)}
														disabled={!formEditable}
													>
														{op.quantity}
													</Input>
													<div className={css.Currency}>Qty</div>
												</div>
											</div>
										</div>
										<div className={css.Delete}>
											<Svg onClick={() => handleDelete(index)}>
												{SvgOptions.Delete}
											</Svg>
										</div>
									</div>
								))}
							</div>
							<div className={css.Submit}>
								{!expenses.submitted && (
									<AddButton onClick={addItem}>Add Item</AddButton>
								)}
							</div>
							<div className={css.Submit}>
								{options && (
									<div className={css.Submit}>
										{!saved ? (
											<Button onClick={save} className="Active Btn-Mid">
												Save
											</Button>
										) : (
											<Button onClick={submit} className="Active Btn-Mid">
												{changed ? "Save" : "Submit for Approval"}
											</Button>
										)}
									</div>
								)}
							</div>
						</div>
					)}
				</div>
			) : (
				<Button
					icon={SvgOptions.Add}
					onClick={addExpense}
					className="Active Btn-Mid mb"
				>
					New Expense List
				</Button>
			)}

			<div className={css.Foot}>
				<div>
					<Button onClick={closeModal} className="Inverse Btn-Mid">
						Close
					</Button>
				</div>
				<div>
					<Button onClick={save} className="Active Btn-Mid">
						Save
					</Button>
				</div>
			</div>
		</div>
	);
};

const RecieptView = ({ children }) => {
	if (children.receipt.value) {
		const { value, type } = children.receipt;
		if (type?.includes("pdf")) {
			return (
				<div className={css.pdf}>
					<object data={value} width={200}></object>
				</div>
			);
		}
		return (
			<div className={css.img}>
				<img src={value} width={200} alt="Receipt" />
			</div>
		);
	}
	return (
		<div>
			<div>Receipt</div>
			<div className={css.UploadTypeName}>PDF Or Image</div>
		</div>
	);
};

export default ExpenseModal;
