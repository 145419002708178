import { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import BarChart from "../../../Components/Charts/BarChart";
import Piechart from "../../../Components/Charts/PieChart";
import EngrTasks from "../../../Components/EngrTasks/EngrTasks";
import Svg from "../../../Components/Svg/Svg";
import SvgOptions from "../../../Components/Svg/SvgOptions";
import TaskItem from "../../../Components/TaskItem/TaskItem";
import { Post } from "../../../Services/Server";
import Url from "../../../Services/Url";
import "./Dashboard.css";

const Dashboard = () => {
  const [EngrList, setEngrList] = useState([]);
  const [OpList, setOpList] = useState([]);
  const [taskCount, setTaskCount] = useState(0);
  const [Tasks, setTasks] = useState({ previous: [], current: [] });
  const [appData, setApp] = useState(null);

  useEffect(() => {
    const pagination = {
      currentTask: 0,
      previousTask: 0,
      taskTypeRange: getRange(),
      engineerTaskRange: getRange(true),
      jobOverview: getRange(true),
    };
    Post(Url.ui.dashboard, pagination)
      .then((resp) => {
        setEngrList(resp.engineers.content);
        setOpList(resp.operations.content);
        setTaskCount(resp.taskCount);
        setApp(resp.appSettings);
        setTasks({ current: resp.currentTasks.content });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getRange = (monthRange) => {
    const d = new Date();
    if (monthRange) {
      const d1 = d.toISOString();
      d.setMonth(d.getMonth() - 1);
      const d2 = d.toISOString();
      return [d1, d2];
    } else {
      const d1 = d.toISOString();
      d.setFullYear(d.getFullYear() - 1);
      const d2 = d.toISOString();
      return [d1, d2];
    }
  };

  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov",
  ];

  return (
    <div className="Dashboard">
      <div className="DBLeft">
        <div className="DBLeftHead">
          <div>
            <h2>Dashboard</h2>
          </div>
          
        </div>
        <div className="DBLeftTop">
          <div>
            <div className="DBPieChart">
              {appData && <Piechart dashboardType="maritime">{appData}</Piechart>}
            </div>
          </div>
          <div className="EngineersTasks">
            <Carousel showThumbs={false} showStatus={false} emulateTouch={true}>
              <div className="TaskCarousel">
                <div className="ETHead">
                  <div>
                    <h4>Engineers tasks</h4>
                  </div>
                  <div>{months[new Date().getMonth()]}</div>
                </div>
                <div className="EngrsTaskList">
                  {EngrList &&
                    EngrList.map((engr, index) => (
                      <EngrTasks key={index}>{engr}</EngrTasks>
                    ))}
                </div>
              </div>
              <div className="TaskCarousel">
                <div className="ETHead">
                  <div>
                    <h4>Operations tasks</h4>
                  </div>
                  <div>{months[new Date().getMonth()]}</div>
                </div>
                <div className="EngrsTaskList">
                  {OpList &&
                    OpList.map((op, index) => (
                      <EngrTasks ops={true} key={index}>
                        {op}
                      </EngrTasks>
                    ))}
                </div>
              </div>
            </Carousel>
          </div>
        </div>
        <Carousel showThumbs={false} showStatus={false} emulateTouch={true}>
          <div className="DBLeftRow">
            {EngrList.length > 0 && <BarChart dashboardType="maritime" chartId="chartEng">{EngrList}</BarChart>}
          </div>
          <div className="DBLeftRow">
            {OpList.length > 0 && <BarChart dashboardType="maritime" op={true} chartId="chartOp">{OpList}</BarChart>}
          </div>
        </Carousel>
      </div>
      <div className="DBRight">
        <h3>{taskCount} Ongoing Tasks</h3>
        <div className="TaskItems">
          {Tasks.current.map((task, index) => (
            <TaskItem key={index}>{task}</TaskItem>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
